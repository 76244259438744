import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import {
  getData,
  getMyDataRequest,
  getMainMyDataRequest,
  getMySendEmailCode,
  postCheckPwd,
  putEmail,
  putSetPassword,
  putWithdrawl,
  updatePassword,
  getSubscribePricePlan,
  getFaqList,
  putLogout,
  postCheckWithdrawalAble,
  postCheckUserStatus,
  putCancelWithdrawal,
  putWithdrawalRequest,
  getCheckUserSbsc,
  getPricePlanByType,
  getMainSbsc,
} from './slice';

/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const MyPageDataSaga = createPromiseSaga(getData, API.Main.MyPage.getMyPageData);
const MainMyDataRequestSaga = createPromiseSaga(getMainMyDataRequest, API.Main.MyPage.getMainMyDataRequest);
const MyDataRequestSaga = createPromiseSaga(getMyDataRequest, API.Main.MyPage.getMyDataRequest);
const setPasswordSaga = createPromiseSaga(putSetPassword, API.Main.MyPage.putSetPassword);
const updatePasswordSaga = createPromiseSaga(updatePassword, API.Main.MyPage.updatePassword);
const getMySendEmailCodeSaga = createPromiseSaga(getMySendEmailCode, API.Main.MyPage.getMySendEmailCode);
const putWithdrawlSaga = createPromiseSaga(putWithdrawl, API.Main.MyPage.putWithdrawl);
const putEmailSaga = createPromiseSaga(putEmail, API.Main.MyPage.putEmail);
const postCheckPwdSaga = createPromiseSaga(postCheckPwd, API.Main.MyPage.postCheckPwd);
const getSubscribePricePlanSaga = createPromiseSaga(getSubscribePricePlan, API.Main.MyPage.getSubscribePricePlanV2);
const putLogoutSaga = createPromiseSaga(putLogout, API.Main.MyPage.putLogout);
const postCheckWithdrawalAbleSaga = createPromiseSaga(postCheckWithdrawalAble, API.Main.MyPage.postCheckWithdrawalAble);
const postCheckUserStatusSaga = createPromiseSaga(postCheckUserStatus, API.Main.MyPage.postCheckUserStatus);
const putCancelWithdrawalSaga = createPromiseSaga(putCancelWithdrawal, API.Main.MyPage.putCancelWithdrawal);
const putWithdrawalRequestSaga = createPromiseSaga(putWithdrawalRequest, API.Main.MyPage.putWithdrawalRequest);
const getCheckUserSbscSaga = createPromiseSaga(getCheckUserSbsc, API.Main.MyPage.getCheckUserSbsc);
const getMainSbscSaga = createPromiseSaga(getMainSbsc, API.Main.MyPage.getMainSbsc);
const getFaqListSaga = createPromiseSaga(getFaqList, API.CustomerService.Faq.getFaqList);
const getPricePlanByTypeSaga = createPromiseSaga(getPricePlanByType, API.Main.MyPage.getPricePlanByType);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getData, MyPageDataSaga);
  yield takeLatest(getMainMyDataRequest, MainMyDataRequestSaga);
  yield takeLatest(getMyDataRequest, MyDataRequestSaga);
  yield takeLatest(putSetPassword, setPasswordSaga);
  yield takeLatest(updatePassword, updatePasswordSaga);
  yield takeLatest(getMySendEmailCode, getMySendEmailCodeSaga);
  yield takeLatest(putWithdrawl, putWithdrawlSaga);
  yield takeLatest(postCheckPwd, postCheckPwdSaga);
  yield takeLatest(putEmail, putEmailSaga);
  yield takeLatest(getSubscribePricePlan, getSubscribePricePlanSaga);
  yield takeLatest(putLogout, putLogoutSaga);
  yield takeLatest(postCheckWithdrawalAble, postCheckWithdrawalAbleSaga);
  yield takeLatest(postCheckUserStatus, postCheckUserStatusSaga);
  yield takeLatest(putCancelWithdrawal, putCancelWithdrawalSaga);
  yield takeLatest(putWithdrawalRequest, putWithdrawalRequestSaga);
  yield takeLatest(getCheckUserSbsc, getCheckUserSbscSaga);
  yield takeLatest(getMainSbsc, getMainSbscSaga);
  yield takeLatest(getFaqList, getFaqListSaga);
  yield takeLatest(getPricePlanByType, getPricePlanByTypeSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
