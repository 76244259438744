export const myPage = ({ http }) => ({
  getMyPageData: ({ params, config }) => http.get('/usr/mypage', { params }, config),
  getMyDataRequest: ({ params, config }) => http.get('/cs/admin/inquiries', { params }, config),
  getMainMyDataRequest: ({ params, config }) => http.get('/cs/admin/inquiries', { params }, config),
  putSetPassword: ({ params, config }) => http.put('/usr/mypage/pwd', { ...params }, config),
  updatePassword: ({ params, config }) => http.put('/usr/mypage/pwd', { ...params }, config),
  getMySendEmailCode: ({ params, config } = {}) => http.put('/usr/mypage/email', params, config),
  putEmail: ({ params, config }) => http.put('/usr/mypage/email/verify-code', { ...params }, config),
  postCheckPwd: ({ params, config }) => http.post('/usr/mypage/verify-pwd', { ...params }, config),
  putWithdrawl: ({ params, config }) => http.put('/usr/mypage/withdrawal', { ...params }, config),
  getSubscribePricePlan: ({ params, config }) => http.get('/usr/mypage/subscribe/plan/detail', { ...params }, config),
  getPricePlanByType: ({ params, config }) => http.get('/usr/mypage/price-plans', { params }, config),
  getSubscribePricePlanV2: ({ config }) => http.get('/usr/mypage/subscribe/list', {}, config),
  putLogout: ({ params, config }) => http.put('/logout', { ...params }, config),
  postCheckWithdrawalAble: ({ params, config }) => http.get('/usr/mypage/check-withdrawal-able', { ...params }, config),
  postCheckUserStatus: ({ params, config }) => http.get('/usr/mypage/check-user-withdrawal-status', { params }, config),
  putCancelWithdrawal: ({ params, config }) => http.put('/usr/mypage/withdrawal-cancel', { ...params }, config),
  putWithdrawalRequest: ({ params, config }) => http.put('/usr/mypage/withdrawal-request', { ...params }, config),
  getCheckUserSbsc: ({ params, config }) => http.get('/usr/mypage/check-sbsc', { params }, config),
  getMainSbsc: ({ params, config }) => http.get('/pp/admin/plans/main', { params }, config),
});
