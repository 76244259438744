import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { COLORS } from '../../../../styles/Colors';
import { PageLayout } from '../../../shared/layout/Layout.Styled';
import MyPage from '../component/MyPage';
import CheckPassword from '../component/CheckPassword';
import ChangeEmail from '../component/ChangeEmail';
import ChangePassword from '../component/ChangePassword';
import { resetStore } from '../redux/slice';
import MyDataRequest from '../component/MyDataRequest';
import SubscribePricePlan from '../component/SubscribePricePlan';
import DeleteAccount from '../component/DeleteAccount';
import rumViewInfos from '../../../../constants/rumViewInfos';
import CancelWithdrawal from '../component/CancelWithdrawal';
import WithdrawalRequest from '../component/WithdrawalRequest';

datadogRum.startView(rumViewInfos.myPage);

function MyPageContainer({ query }) {
  const dispatch = useDispatch();
  const [step, setStep] = useState('check-pass'); // check-pass, mypage, change-email, change-pass, delete-account, price-plan, data-request
  const [isEnterMain, setIsEnterMain] = useState(false);

  useEffect(() => () => {
    dispatch(resetStore());
  }, []);

  useEffect(() => {
    if ((step !== 'check-pass' || query?.passSkip) && query?.step) {
      if (query.step === 'price-plan') {
        setStep(query.step);
        setIsEnterMain(true);
      } else {
        setStep(query.step);
      }
    }
  }, [query]);

  const onMoveStep = (stepName) => {
    if (!(stepName === 'withdrawal-request' || stepName === 'cancel-withdrawal')) {
      dispatch(resetStore());
    }
    setStep(stepName);
  };

  const renderStep = () => {
    switch (step) {
    case 'mypage': {
      return (
        <MyPage moveStep={onMoveStep} />
      );
    }
    case 'change-email': {
      return (
        <ChangeEmail moveStep={onMoveStep} />
      );
    }
    case 'change-pass': {
      return (
        <ChangePassword moveStep={onMoveStep} />
      );
    }
    case 'data-request': {
      return (
        <MyDataRequest moveStep={onMoveStep} />
      );
    }
    case 'price-plan': {
      return (
        <SubscribePricePlan moveStep={onMoveStep} isEnterMain={isEnterMain} />
      );
    }
    case 'delete-account': {
      return (
        <DeleteAccount moveStep={onMoveStep} />
      );
    }
    case 'cancel-withdrawal': {
      return <CancelWithdrawal moveStep={onMoveStep} />;
    }
    case 'withdrawal-request': {
      return <WithdrawalRequest moveStep={onMoveStep} />;
    }
    default: {
      return (<CheckPassword moveStep={onMoveStep} />);
    }
    }
  };

  return (
    <Container>
      {
        renderStep()
      }
    </Container>
  );
}

export default MyPageContainer;

const Container = styled(PageLayout)`
  .margin_loginDtm {
    margin-right: 10px;
    font-size: 13px;
    font-weight: 400;
    color: ${COLORS.STEELGRAY[700]};
  }
`;
