import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { useUserRole } from '@hooks/useUserRole';

import RGDTable from '@components/Reactdatagrid';

import { cloneDeep } from 'lodash-es';

import { PageTypes } from '@constants/pageType';

import Button from '@components/ButtonNew';
import CustomLabel from '@components/Form/components/CustomLabel';

import CustomTitleForm from '@components/Form/components/CustomTitleForm';

import { CustomCol, CustomRow } from '@styles/Common.Styled';

import PageHeader from '../../../../components/Header/PageHeader';
import Paper from '../../../../components/Paper';
import Images from '../../../../Images';
import { COLORS } from '../../../../styles/Colors';
// import { getCodeTextByValue, transformNoTimeString } from '../../../../utils/utils';
import { usePageTab } from '../../../shared/PageTab/hooks/usePageTab';
import { getData, getMainMyDataRequest, getMyDataRequest, getSubscribePricePlan, postCheckUserStatus, postCheckWithdrawalAble } from '../redux/slice';
import { alertMessage, confirmMessage } from '../../../../components/Message';
import { lstShowRatePlan, tableColumn } from './constants';

function MyPage({ moveStep }) {
  const dispatch = useDispatch();
  const { openPage } = usePageTab();
  const history = useHistory();
  const userRole = useUserRole();

  const [tableData, setTableData] = useState([]);

  const { myPageData, userInfo, checkedWithdrawalAble, checkedUserStatus, subscribePricePlan } = useSelector((store) => ({
    myPageData: store.main.myPage.myPageData,
    mainMyDataRequest: store.main.myPage.mainMyDataRequest,
    userInfo: store.common.userInfo,
    checkedWithdrawalAble: store.main.myPage.checkedWithdrawalAble,
    checkedUserStatus: store.main.myPage.checkedUserStatus,
    subscribePricePlan: store.main.myPage.subscribePricePlan,
  }));

  useEffect(() => {
    dispatch(getData());
    const params = {
      page: 0,
      size: 5,
    };
    dispatch(getMainMyDataRequest({ params }));
    dispatch(getSubscribePricePlan());
  }, [userInfo]);

  useEffect(() => {
    if (checkedWithdrawalAble?.status === 'success' && checkedWithdrawalAble.data?.withdrawalNow === 'N') {
      moveStep('withdrawal-request');
    } else if (checkedWithdrawalAble?.status === 'success' && checkedWithdrawalAble?.data?.withdrawalNow === 'Y') {
      moveStep('delete-account');
    } else if (checkedWithdrawalAble?.status === 'error') {
      if (checkedWithdrawalAble.data?.data?.error?.errorCode === '0125') {
        confirmMessage(
          checkedWithdrawalAble.data?.data?.error?.errorDescription,
          () => {
            // ok 콜백
            history.push('/user');
          },
          '사용자 관리하기',
          '취소',
          () => {
          },
        );
      } else if (checkedWithdrawalAble.data?.data?.error?.errorCode === '0540') {
        confirmMessage(
          checkedWithdrawalAble.data?.data?.error?.errorDescription,
          () => {
            // ok 콜백
            history.push('/userDivision');
          },
          '권한 이관하기',
          '취소',
          () => {
          },
        );
      }
    }
  }, [checkedWithdrawalAble]);

  useEffect(() => {
    if (checkedUserStatus?.status === 'success' && checkedUserStatus?.data?.canCancel) {
      moveStep('cancel-withdrawal');
    } else if (checkedUserStatus?.status === 'error') {
      if (checkedUserStatus.data?.data?.error?.errorCode === '0132') {
        alertMessage(
          <>
            <div>
              {checkedUserStatus.data?.data?.error?.errorDescription}
            </div>
          </>,
          () => {
          //
          },
        );
      }
    }
  }, [checkedUserStatus]);

  useEffect(() => {
    const arr = cloneDeep(subscribePricePlan.data?.sbscInfo)?.filter((x) => lstShowRatePlan.includes(x.sbscStDvNm));
    if (arr?.length > 0) {
      arr.push(
        {
          ppNm: '월 이용료 합계 (VAT 별도)',
          sbscStDvCd: '',
          sbscStaDtm: '',
          sbscEndDtm: '',
          usfeeAmt: subscribePricePlan.data?.totalUsfeeAmt,
        },
      );
      setTableData(arr);
    }
  }, [subscribePricePlan.data?.sbscInfo]);

  const handleDeleteAccountBtn = useCallback(() => {
    dispatch(postCheckWithdrawalAble());
  }, []);

  const handleCancelWithdrawalBtn = useCallback(() => {
    if (myPageData.data?.dueDate && moment().diff(moment(myPageData.data?.dueDate), 'days') >= 1) {
      alertMessage(
        (
          <>
            <div>
              이미 탈퇴가 완료된 회원입니다.
              <br />
              그동안 GIP을 이용해주셔서 감사드립니다.
            </div>
          </>
        ), () => {
          window.sessionStorage.removeItem('GIPADMIN_USER');
          window.location.href = '/Intro';
        },
      );
    } else if (userInfo) { dispatch(postCheckUserStatus({ params: { usrId: userInfo.usrId } })); }
  });

  const handleClickSubscribePricePlan = useCallback(() => {
    if (userInfo.roleId === 'RO000000004') {
      moveStep('price-plan');
    }
  }, []);

  const onChangePassword = useCallback(() => {
    moveStep('change-pass');
  }, []);

  const onChangeEmail = useCallback(() => {
    moveStep('change-email');
  }, []);

  const handleClickDataRequst = useCallback(() => {
    const params = {
      usrDvCd: userInfo?.usrDvCd,
      corpId: userInfo?.corpId,
      salesChnlCd: userInfo?.salesChnlCd,
    };
    dispatch(getMyDataRequest({ params }));
    moveStep('data-request');
  }, []);

  const handleClickSubs = () => {
    openPage(PageTypes.SUBSCRIBEVIEWS);
  };

  // const onClickLogout = useCallback(() => {
  //   dispatch(logout());
  // }, []);

  return (
    <Container>
      <PageHeader
        title={(
          <>
            <span>{userInfo.loginNm}</span>
            {/* 마스터 */}
            {
              userRole.isUserMaster && (
                <img src={Images.badge_master} style={{ verticalAlign: 'baseline', paddingLeft: '12px' }} alt="badge_master" />
              )
            }
            {/* 일반 */}
            {
              userRole.isUser && (
                <img src={Images.user_normal_badge} style={{ verticalAlign: 'baseline', paddingLeft: '12px' }} alt="badge_normal" />
              )
            }
            {/* 임직원 */}
            {
              userRole.isGSAdmin && (
                <img src={Images.user_manager_badge} style={{ verticalAlign: 'baseline', paddingLeft: '12px' }} alt="badge_manager" />
              )
            }
          </>
        )}
      >
        <span className="margin_loginDtm">
          최근접속일시:
          {' '}
          {myPageData.data.loginDtm}
        </span>
        {/* <Button onClick={() => onClickLogout()}>로그아웃</Button> */}
      </PageHeader>
      {myPageData.data?.usrStCd === '009' && (
        <UserStatusContainer>
          <UserStatusInfos>
            <UserStatusText>
              {`${myPageData?.data?.pnm}
              님은 회원탈퇴를 신청하였습니다.`}
            </UserStatusText>
            <UserStatusDuration>
              <UserStatusDate>
                {moment(myPageData.data?.dueDate).format('YYYY.MM.DD')}
              </UserStatusDate>
              <UserStatusDurationText>
              &nbsp;까지 GIP 서비스를 사용하실 수 있습니다.
              </UserStatusDurationText>
            </UserStatusDuration>
          </UserStatusInfos>
          <div>
            <SecessionWrap className="cancel-withdrawal" onClick={() => handleCancelWithdrawalBtn()}>탈퇴 신청 철회</SecessionWrap>
          </div>
        </UserStatusContainer>
      )}
      <Contents>
        <MyPagePaper border margin="0 0 24px 0">
          <CustomTitleForm
            title="기본정보"
          />
          <CustomRow className="gap-60">
            <CustomCol span={12}>
              <CustomLabel
                type="Text"
                title="이름"
                width="100%"
                defaultValue={myPageData.data.pnm}
              />
            </CustomCol>
            <CustomCol flex="auto">
              <CustomLabel
                type="Text"
                title="휴대폰 번호"
                width="100%"
                defaultValue={myPageData.data.cellNo}
              />
            </CustomCol>
          </CustomRow>
          <CustomTitleForm
            title="보안정보"
          />
          <CustomRow className="gap-60">
            <CustomCol span={12}>
              <CustomLabel
                type="Text"
                title="비밀번호"
                showButton
                positionButton="END"
                textButton="비밀번호 변경"
                defaultValue={myPageData.data.pwd}
                onClickButton={onChangePassword}
              />
            </CustomCol>
            <CustomCol span={12}>
              <CustomLabel
                type="Text"
                title="이메일"
                showButton
                positionButton="END"
                textButton="이메일 변경"
                defaultValue={myPageData.data.emailAddr}
                onClickButton={onChangeEmail}
              />
            </CustomCol>
          </CustomRow>
        </MyPagePaper>
        {
          (myPageData.status === 'success' && (userInfo.roleId === 'RO000000004' || userInfo.roleId === 'RO000000005')) && (
            <>
              {userInfo.roleId === 'RO000000004' && (
                <MyPagePaper border margin="0 0 24px 0">
                  <TitleWrap heightChart={tableData.length === 0 && '80px'}>
                    <div className="space-between header-grid-rate-plan">
                      <span className="title-grid-rate-plan">구독 중인 요금제</span>
                      <div className="button-group">
                        <DetailButtonWrap textColor={COLORS.GRAY[900]}>
                          <Button type="button" onClick={handleClickSubs}>구독 관리 메뉴</Button>
                        </DetailButtonWrap>
                        <DetailButtonWrap textColor={COLORS.GRAY[900]}>
                          <Button type="button" onClick={handleClickSubscribePricePlan}>전체 요금제 보기</Button>
                        </DetailButtonWrap>
                      </div>
                    </div>
                    <RGDTable
                      columns={tableColumn}
                      data={tableData}
                      minRowHeight={40}
                      reorderColumns={false}
                      rowClassName="custom-row"
                      emptyText="구독 중인 요금제가 없습니다."
                      customBorder={{
                        style: `2px solid ${COLORS.GRAY[200]} !important;`,
                        index: 1,
                      }}
                    />
                  </TitleWrap>
                </MyPagePaper>
              )}
              <MyPagePaper border margin="0 0 16px 0">
                <CustomTitleForm title="법인정보" />
                <CustomRow>
                  <CustomCol span={12}>
                    <CustomLabel
                      type="Text"
                      title="법인번호"
                      defaultValue={myPageData.data.myPageCorpInfo?.corp_reg_no}
                    />
                  </CustomCol>
                  <CustomCol span={12}>
                    <CustomLabel
                      type="Text"
                      title="사업자번호"
                      defaultValue={myPageData.data.myPageCorpInfo?.entpr_reg_no}
                    />
                  </CustomCol>
                </CustomRow>
                <CustomRow>
                  <CustomCol span={12}>
                    <CustomLabel
                      type="Text"
                      title="사업자명"
                      defaultValue={myPageData.data.myPageCorpInfo?.entpr_nm}
                    />
                  </CustomCol>
                  <CustomCol span={12}>
                    <CustomLabel
                      type="Text"
                      title="판매채널"
                      defaultValue={myPageData.data.myPageCorpInfo?.sales_chnl_nm}
                    />
                  </CustomCol>
                </CustomRow>
                <CustomRow>
                  <CustomCol span={12}>
                    <CustomLabel
                      type="Text"
                      title="법인명"
                      defaultValue={myPageData.data.myPageCorpInfo?.corp_ali}
                    />
                  </CustomCol>
                  <CustomCol span={12}>
                    <CustomLabel
                      type="Text"
                      title="사용자수"
                      menuProvided
                      className="styled-custom-label"
                      defaultValue={(
                        <DetailContent>
                          <div className="badges-user">
                            <div className="badge-user">
                              <div className="title-1">
                                마스터
                              </div>
                              <span className="value">
                                1명
                              </span>
                            </div>
                            <div className="badge-user">
                              <div className="title-2">
                                일반
                              </div>
                              <span className="value-2">
                                {myPageData.data.myPageCorpInfo?.generalNo}
                                명
                                {' '}
                                <span className="grey-text">
                                  /
                                  {' '}
                                  {myPageData.data.myPageCorpInfo?.maxGeneralNo}
                                  명
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="desc-user">
                            마스터회원을 제외한
                            {' '}
                            {myPageData.data.myPageCorpInfo?.maxGeneralNo}
                            명의 일반회원이 사용할 수 있습니다.
                            <br />
                            현재 가입한 회원은
                            {' '}
                            <span className="bold">
                              {`${myPageData.data.myPageCorpInfo?.generalNo}명`}
                            </span>
                            입니다.
                          </div>
                        </DetailContent>
                      )}
                    />
                  </CustomCol>
                </CustomRow>
              </MyPagePaper>
            </>
          )
        }
        {
          (userInfo.roleId === 'RO000000004' || userInfo.roleId === 'RO000000005') && myPageData?.data?.usrStCd !== '009' && (
            <SecessionWrap onClick={() => handleDeleteAccountBtn()}>회원탈퇴</SecessionWrap>
          )
        }

      </Contents>
    </Container>
  );
}

const Container = styled.div`
  .margin_loginDtm {
    margin-right: 10px;
    font-size: 13px;
    font-weight: 400;
    color: ${COLORS.STEELGRAY[700]};
  }
`;

const Contents = styled.div`
  .ant-divider-horizontal {
    margin-top: 10px !important;
  }
  .rate-plan-ant-diviver-horizontal {
    margin: 12px 0 0 0 !important;
  }
`;

const MyPagePaper = styled(Paper)`
  margin: ${(props) => props.margin};
  .margin-bottom {
    margin-bottom: 16px !important;
  }
  .space-between {
    display: flex;
    justify-content: space-between
  }
  .margin-detail {
    margin-bottom: 24px
  }

  padding: 24px;

  .center {
    justify-content: center;
  }
  .styled-custom-label {
    .title {
      min-width: 120px;
    }
  }
`;

const UserStatusContainer = styled.div`
  font-family: 'Pretendard';
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  gap: 40px;
  border: 1px solid ${COLORS.GRAY[200]};
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 13px;
`;

const UserStatusInfos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const UserStatusText = styled.div`
  color: #313747;
`;

const UserStatusDuration = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
`;

const UserStatusDate = styled.span`
  color: #0091FF;
  font-weight: 700;
  line-height: 20px;
`;

const UserStatusDurationText = styled.span`
  font-weight: 700;
  color: #333333;
`;

const TitleWrap = styled.div`
  .title {
    color: ${COLORS.GRAY[900]};
    font-size: 16px;
    font-weight: 700;
    height: 32px;
    line-height: 24px;
    border-bottom: 1px solid ${COLORS.GRAY[200]};
  }
  margin: ${(props) => props.margin};
  
  color: ${COLORS.STEELGRAY[900]};

  ${(props) => {
    if (props.isClick) {
      return `
        cursor: pointer;
      `;
    }
    return '';
  }};

  .button-group {
    display: flex;
    flex-wrap: no-wrap;
    gap: 10px;
    > div {
      margin: 0px;
    }
    button {
      padding: 5px 10px 5px 10px;
      font-family: Pretendard;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;
      color:  ${COLORS.STEELGRAY[900]};

    }
  }
  .InovuaReactDataGrid {
    height: ${(props) => props.heightChart} !important;
    min-height: unset;
  }
  .data-grid-table.InovuaReactDataGrid {
    &:before {
      width: 0%;
    }
    &:after {
      width: 0%;
    }
  }
  .InovuaReactDataGrid__empty-wrapper, .InovuaReactDataGrid__loading-wrapper {
    overflow: unset !important;
    height: 40px;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: ${COLORS.GRAY[900]};
  }
  .InovuaReactDataGrid--theme-default-light 
  .InovuaReactDataGrid__column-header--show-border-left, 
  .InovuaReactDataGrid--theme-default-light 
  .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left, 
  .InovuaReactDataGrid--theme-default-light 
  .InovuaReactDataGrid__header-group__title--show-border-left  {
    border-left: none !important;
  }
  .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__cell--show-border-left {
    border-left: none !important;
  }
  .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--no-zebra:last-child  {
    background: #FFF4E6;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: right;
  }
  .custom-row:last-child {
    .InovuaReactDataGrid__row-hover-target {
      background: #FFF4E6 !important;
    }
  }
  .flex {
    display: flex;
    gap: 10px;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .badge-basic, .badge-additional {
    border: 1px solid #0091FF;
    border-radius: 2px;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    color: #0091FF;
    min-width: 29px;
    height: 18px
  }
  .badge-additional {
    border: 1px solid #FDA535;
    color: #FDA535;
  }
  .text-no-data-grid {
    height: 20px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: ${COLORS.GRAY[900]};
    margin: 10px 0;
  }
  .header-grid-rate-plan {
    height: 34px;
  }
  .title-grid-rate-plan {
    margin: auto 0 0;
    font-size: 16px;
    font-weight: 700;
    color: ${COLORS.GRAY[900]};
  }
`;

const DataRequestWrap = styled.div`
  display: flex;
  flex: 1;
  .pricePlanTitle {
    font-weight:700
  }
  .marginLeft {
    margin-left: 12px;
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  >div {
    margin-right: 30px;
  }
  >span {
    margin-right: 20px;
  }
`;

const DetailButtonWrap = styled.div`
  ${(props) => props.textColor && `
  button {
    color: ${props.textColor} !important;
  }
  `}
`;

const DetailPasswordWrap = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const DetailTitle = styled.div`
  min-width: 90px;
  color: ${COLORS.GRAY[900]} !important;
  min-height: 34px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
`;

const DetailContent = styled(CustomLabel)`
    max-width: 301px;
    font-size: 13px;
    color: ${COLORS.GRAY[900]};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .badges-user {
      display: flex;
      width: 100%;
      gap: 30px;
      .badge-user {
        display: flex;
        gap: 10px;
        div {
          height: 18px !important;
        }
        [class^="title"] {
          width: 40px !important;
          align-items: center;
          display: flex;
          justify-content: center;
          font-family: Pretendard;
          font-size: 12px;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: 0px;
          border-radius: 2px;
        }
        [class^="value"] {
          font-family: Pretendard;
          display: flex;
          align-items: center;
          font-size: 13px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: center;
        }
        .title-2 {
          color: #67728D;
          background: #EEEFFA;
        }
        .title-1 {
          color: #0077E2;
          background: #D9EEFF;
        }
        .value-2 .grey-text {
          font-weight: 400;
          color: #8F959D;
        }
      }
    }
    .desc-user {
      margin-top: 8px;
      font-family: Pretendard;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color: #666666;
      .bold {
        font-weight: 700;
        color: #0091FF;
      }
    }
`;

const SecessionWrap = styled.a`
  border-bottom: 1px solid;
  height: 26px;
  margin-right: 10px;
  font-size: 13px;
  &.cancel-withdrawal {
    margin-right: 0px;
  }
`;

export default MyPage;
